import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    //   height: 48,
    //   position: 'absolute',
    //   left: 0,
    //   bottom: 0,
    //   width: '100%',
    //   textAlign: 'center',
    flexShrink: 0,
    padding: '20px',
    textAlign: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box py={1} className={classes.root}>
      All rights reserved 2020
    </Box>
  );
};

export default Footer;
