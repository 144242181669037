import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import { GlobalStateContext } from '@components/Context';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    top: 0,
    color: theme.palette.text.primary,
    height: theme.spacing(6),
    alignItems: 'center',
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    flexDirection: 'row-reverse',
    '& p': {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.body2.sm.fontSize,
      },
    },
  },
  title: {
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.sm.fontSize,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  ul: {
    textDecoration: 'none',
    listStyle: 'none',
    display: 'flex',
    marginRight: '5%',
    '& li': {
      margin: `0 ${theme.spacing(1)}px`,
    },
    '& a': {
      color: theme.palette.text.primary,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const context = useContext(GlobalStateContext);
  const { data } = context;
  return (
    <Box py={1} className={classes.root}>
      <Box display="flex" className={classes.title}>
        <Typography variant="h6">
          {data ? data.title : ''}
        </Typography>
      </Box>
      <Box display="flex" className={classes.list}>
        <ul className={classes.ul}>
          <li>
            <Link to="/">
              <Typography variant="body2">
                Home
              </Typography>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <Typography variant="body2">
                About Me
              </Typography>
            </Link>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default Header;
