import React from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import PropTypes from 'prop-types';

const Layout = ({ children }) => (
  <>
    <Header />
    <div style={{ flex: '1 0 auto' }}>
      {children}
    </div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default Layout;
